import React, {useState, useEffect} from 'react';
import { services } from "../data/services";
import CustomizedSnackbars from "../components/CustomizedSnackbar";
import { CircularProgress } from "@material-ui/core";
import Axios from "axios";

export const Home2ndOpinion = () => {
    const source = require("../data/2ndOpinion.json");
	const data = source["DIAGNOSIS"];

	const [formData, setFormData] = useState({
		name: "",
		email: "",
		phone: "",
		message: "",
	});
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [severity, setSeverity] = useState("");
	const [disableButton, setDisableButton] = useState(false);

	const formatPhone = (phone) => {
		var d = phone[0];
		if (d === "+") return phone;
		return "+234" + phone;
	};

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]:
				e.target.name === "phone"
					? formatPhone(e.target.value)
					: e.target.value,
		});
	};

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpen(false);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setDisableButton(true);
		Axios.post("https://asess-econsult.herokuapp.com/api/v1/second-opinion/book_appointment/", formData, {})
			.then((res) => {
				setDisableButton(false);
				setMessage(
					"Your booking has been sent successfully. Please check your email address now to fill out the PATIENT MEDICAL FORM!",
				);
				setSeverity("success");
				setOpen(true);
			})
			.catch((err) => {
				console.log(err);
				setDisableButton(false);
				setMessage(
					"Sorry, there was an error while trying to send your booking. Please check your network settings and try again.",
				);
				setSeverity("error");
				setOpen(true);
			});
	};

    return (
        <>
				{/* start section */}
				<section id="appointment" className="py-0">
					<div className="container-fluid">
						<div className="row">
							<div className="col-12 col-lg-6 bg-light-iris-blue padding-10-rem-left padding-4-rem-tb d-flex flex-column justify-content-center xl-padding-15px-left md-padding-6-rem-all xs-padding-15px-lr wow animate__fadeIn">
								<div className="row justify-content-lg-center">
									<div className="col-12 col-xl-7 col-md-11 col-sm-10">
										<h5 className="alt-font font-weight-600 letter-spacing-minus-1px text-extra-dark-gray margin-5-rem-bottom">
											No worries. No travel.
											<p>
												Get your medical care by phone or video from the comfort of your home.
											</p>
										</h5>
										<ul className="list-style-06">
											<li
												className="margin-40px-bottom last-paragraph-no-margin d-flex align-items-center wow animate__fadeIn"
												data-wow-delay="0.2s"
											>
												<div>
													<span className="w-30px h-30px text-center bg-iris-blue rounded-circle text-light margin-25px-right d-flex flex-column">
														<i className="fas fa-check" />
													</span>
												</div>
												<div>
													<span className="alt-font text-extra-medium text-dark-charcoal font-weight-500 margin-5px-bottom d-block">
														Start by calling our doctors on call service at +2349112992719 or +2347019151778
													</span>
													{/* <p>Lorem ipsum dolor sit amet consectetur</p> */}
												</div>
											</li>
											<li
												className="margin-40px-bottom last-paragraph-no-margin d-flex align-items-center wow animate__fadeIn"
												data-wow-delay="0.4s"
											>
												<div>
													<span className="w-30px h-30px text-center bg-iris-blue rounded-circle text-light margin-25px-right d-flex flex-column">
														<i className="fas fa-check" />
													</span>
												</div>
												<div>
													<span className="alt-font text-extra-medium text-dark-charcoal font-weight-500 margin-5px-bottom d-block">
														Activate your 2nd medical opinion free membership here and one of our doctors will connect with you
													</span>
													{/* <p>Lorem ipsum dolor sit amet consectetur</p> */}
												</div>
											</li>
											<li
												className="margin-40px-bottom last-paragraph-no-margin d-flex align-items-center wow animate__fadeIn"
												data-wow-delay="0.4s"
											>
												<div>
													<span className="w-30px h-30px text-center bg-iris-blue rounded-circle text-light margin-25px-right d-flex flex-column">
														<i className="fas fa-check" />
													</span>
												</div>
												<div>
													<span className="alt-font text-extra-medium text-dark-charcoal font-weight-500 margin-5px-bottom d-block">
														Connect with a specialist by phone or video at your convenience via the eConcult platform
													</span>
													{/* <p>Lorem ipsum dolor sit amet consectetur</p> */}
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div
								className="col-12 col-lg-6 bg-light-iris-blue padding-10-rem-all xl-padding-8-rem-all lg-padding-6-rem-all xs-padding-15px-lr wow animate__fadeIn"
								data-wow-delay="0.5s"
							>
								<h4 className="alt-font font-weight-600 margin-5-rem-bottom md-margin-25px-bottom text-iris-blue">
									<span className="text-decoration-line-bottom-medium">
										Book appointment
                                        </span><br/>
                                        now
								</h4>
								<form
								autoComplete='off'
									onSubmit={handleSubmit}
							
									className="alt-font text-extra-dark-gray"
								>
									<input
					
										className="input-border-bottom border-color-dark-white-transparent bg-transparent-light large-input text-dark margin-25px-bottom border-radius-0px required"
										type="text"
										name="name"
										placeholder="Your name"
										onChange={handleChange}
										defaultValue={formData.name}
										required
									/>
									<input
					
										className="input-border-bottom border-color-dark-white-transparent bg-transparent-light large-input text-dark margin-25px-bottom border-radius-0px required"
										type="email"
										name="email"
										placeholder="Your email address"
										onChange={handleChange}
										defaultValue={formData.email}
										required
									/>
									<input
					
										className="input-border-bottom border-color-dark-white-transparent bg-transparent-light large-input text-dark margin-25px-bottom border-radius-0px"
										type="tel"
										name="phone"
										placeholder="Mobile number"
										onChange={handleChange}
										defaultValue={formData.phone}
										required
									/>
									<textarea
										className="input-border-bottom border-color-dark-white-transparent bg-transparent-light large-input text-dark margin-15px-bottom border-radius-0px"
										name="message"
										rows={3}
										placeholder="How can we help you?"
										defaultValue={""}
										onChange={handleChange}
										value={formData.message}
										required
									/>
									{/* <input type="hidden" name="redirect" defaultValue /> */}
									<button
										className="btn btn-primary btn-large w-100"
										type="submit"
										disabled={disableButton}
										style={{
                                            width: disableButton && "250px",
											cursor: disableButton ? "not-allowed" : "pointer",
										}}
									>
										{disableButton ? (
                                            <>
											<CircularProgress
												size={25}
												style={{ marginRight: "20px", marginBottom: -7.5 }}
											/>
											Processing, please wait...
											</>
										) : (
                                            "Book appointment"
										)}
									</button>
									<div className="form-results d-none" />
								</form>
								<CustomizedSnackbars
									notify={open}
									closeNotify={handleClose}
									message={message}
									severity={severity}
								/>
							</div>
						</div>
					</div>
				</section>
				{/* end section */}
				{/* start section */}

                <div className="container">
            <div className="row row-cols-1 row-cols-lg-3 row-cols-sm-2">
                {Object.entries(data).map((item, i) => {
                    {/* console.log(item, "opinion") */}
                    return (
                        <div
                            key={"ahsssssd" + i}
                            className="col col-sm-8 interactive-banners-style-09 lg-margin-30px-bottom xs-margin-15px-bottom wow animate__fadeIn"
                            data-wow-delay="0.2s"
                            style={{
                                visibility: "visible",
                                animationDelay: "0.2s",
                                animationName: "fadeIn",
                                marginBottom: "2rem",
                            }}
                        >
                            <figure className="m-0" style={{ borderRadius: 10 }}>
                                <img
                                    className="w-100"
                                    src={require(`../media/images/${item[1][1]}`).default}
                                    alt=""
                                    data-no-retina
                                />
                                <div className="opacity-light bg-extra-dark-gray" />
                                <div className="interactive-banners-overlay bg-transparent-gradient-fast-blue-purple" />
                                <figcaption>
                                    <div className="interactive-banners-content align-items-start padding-4-half-rem-lr padding-5-rem-tb last-paragraph-no-margin xl-padding-2-rem-all lg-padding-4-rem-all xs-padding-5-rem-all">
                                        <span className="text-extra-medium text-white opacity-6 d-block margin-10px-bottom position-relative z-index-1">
                                            0{i + 1}
                                        </span>
                                        <h5 className="alt-font font-weight-600 text-white position-relative z-index-1 text-capitalize">
                                            {item[0]}
                                        </h5>
                                        <span className="interactive-banners-hover-icon">
                                            <i className=" line-icon-Betvibes  text-white icon-large" />
                                        </span>
                                    </div>
                                    <div className="interactive-banners-hover-action align-items-end d-flex">
                                        <div className="padding-4-half-rem-lr padding-5-rem-tb last-paragraph-no-margin lg-padding-4-rem-all xl-padding-3-rem-all xs-padding-5-rem-all">
                                            <p className="interactive-banners-action-content w-80 text-white opacity-6 lg-w-70">
                                                {item[1][0]}
                                            </p>
                                            {/* <a
                                                href={item?.link}
                                                className="btn btn-link btn-extra-large text-white margin-20px-top"
                                            >
                                                Explore services
                                            </a> */}
                                        </div>
                                    </div>
                                </figcaption>
                            </figure>
                        </div>
                    );
                })}
            {/* </div>                 */}
            </div>
            </div>
            {/* <div class="container">
                <div class="text-center">
                    <h6 class="text-dark">OUR MEDICAL AND SURGICAL SPECILAISTS CAN HELP YOU CLARIFY YOUR DIAGNOSIS </h6>
                </div>
                <div class="row border font-weight-500">
                    {data.map((a, b) =>
                                <div class="border faq-table-cell" dangerouslySetInnerHTML={{__html: a}}>
                                </div>
                    )}            
                </div>
            </div> */}
        {/* <br/><br/> */}
        </>
    )
}
