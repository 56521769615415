/* eslint-disable jsx-a11y/heading-has-content */
import React from "react";
import { useState } from "react";
import { Layout } from "../components/Layout";
import { testimonial, partners, team } from "./constants";
import TeamWithCarousel from "../components/TeamWithCarousel";

export const CallCenter = () => {
	const source = require("../data/callCenter.json");
	const data = source["diseases"];
  const data2 = source["psychiatry"]

	const [formData, setFormData] = useState({
		name: "",
		email: "",
		phone: "",
		message: "",
	});
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [severity, setSeverity] = useState("");
	const [disableButton, setDisableButton] = useState(false);

  return (
    <Layout
      subtitle={""}
      title={"24/7 doctors on call service"}
      bgimage={require("../media/images/e-health/call-center.jpg").default}
      jumpto="services"
    >
      <section
        id="services"
        className="position-relative background-no-repeat parallax overlap-height md-no-overlap-section"
        data-parallax-background-ratio="0.5"
      >
        <div className="opacity-medium bg-white" />
        <div className="container">
          {/* <div className="row justify-content-center" style={{fontSize: "17px"}}>
            <div className="col-12 col-lg-6 col-md-6 col-sm-8 md-margin-30px-bottom xs-margin-15px-bottom">
            </div>
          </div>  */}
          <div className="row justify-content-center" style={{fontSize: "17px"}}>
            <div className="col-12 col-lg-6 col-md-6 col-sm-8 md-margin-30px-bottom xs-margin-15px-bottom">
                  <span>Call our doctors on call for immediate medical questions and management of your medical conditions wherever you are and at your convenience.</span>
				  <br/><span> CALL US NOW! You call, we answer...</span>
				  <br/><span>070 4866 3726, 070 4019 9716, 091 357 82822</span>
            </div>
            <div className="col-12 col-lg-6 col-md-6 col-sm-8 md-margin-30px-bottom xs-margin-15px-bottom">
                  <span>If your condition cannot be managed over the phone, our doctors on call will either refer you to the nearest hospital in your area or assist you with scheduling an appointment to speak with a doctor specialized in the taking care of your medical condition.</span>
            </div>
          </div>		  
        </div>
      </section>
	  {Object.entries(data).map((a, b) =>
			<div class="container">
				<div class="row alt-font text-extra-dark-gray font-weight-500">
					<h5 class="text-center w-100">{a[0]}</h5>
				</div>
				<div class="row font-weight-500" style={{
          gap: "10px"
        }}>
					{Object.entries(a[1]).map(item=> 
							<div class="call-center-table-cell">
								<span class="d-block">{item[0]}</span>
                <img
                  src={require(`../media/diseases/${item[1]}`).default} 
                />
						</div>
						)}
				</div>
				<br/><br/><br/>
			</div>
		)}
    
    <section className="pt-1">
			<div class="container">
				<div class="row alt-font text-extra-dark-gray font-weight-500">
					<h5 class="text-center w-100">PSYCHIATRY</h5>
				</div>
				<div class="row font-weight-500">
				<div className="row w-100 mx-0">
					<div className="col">
						<div
							className="swiper-container black-move swiper-pagination-bottom"
							data-slider-options='{ "loop": false, "slidesPerView": 1, "observer": true, "observeParents": true, "breakpoints": { "1200": { "slidesPerView": 1 }, "992": { "slidesPerView": 1 }, "768": { "slidesPerView": 1 } } }'
						>
							<div className="swiper-wrapper my-2">
								{/* start team member slider item */}

										<div className="swiper-slide">
											<div className="team-style-01 text-center">
												<figure className="border-radius-5px">
													<div className="team-member-image">
														<img
															className="slide-team-img"
															alt=""
															src={
																require("../media/diseases/PSYCHIATRY.png").default
															}
														/>
														<div className="team-overlay border-radius-5px" />
													</div>
													<figcaption className="d-flex flex-column padding-60px-lr padding-50px-tb md-padding-30px-all view-scroll">
                          							{Object.entries(data2).map((item, i) => {
														  return(
														<span className="team-title d-block padding-one-bottom alt-font text-extra-small text-white font-weight-500 mt-auto">
															{item[0]}
														</span>)
							                          })}
													</figcaption>
												</figure>
											</div>
										</div>
							</div>
							{/* start slider pagination */}
							<div className="swiper-pagination" />
							{/* end slider pagination */}
						</div>
					</div>
				</div>
				</div>
			</div>
		</section>    


    </Layout>
  );
};

export const OurTeam = ({ team }) => {
	return <TeamWithCarousel title='Meet Our Team' team={team} />;
};