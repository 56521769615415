import React from "react";
import { Layout } from "../components/Layout";
import { ServiceAbout } from "../components/ServiceAbout";
import { OurTeam } from "./HomeInterior";
import { partners } from "./constants";
import TextTransition, { presets } from "react-text-transition";

export const AnimatedText = () => {
  const [index, setIndex] = React.useState(0);

  const TEXTS = ["Our Mission", "Our Vision", "Our Value"];

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <TextTransition
      text={TEXTS[index % TEXTS.length]}
      springConfig={presets.gentle}
      // direction={"right"}
    />
  );
};

export const About2ndOpinion = () => {
  const people = [
    {
      image: "",
      name: "Naomi Peters",
      position: "Managing Director",
      note: "Sound commercial, negotiation, communication and interpersonal skills with over 15 years in leadership and strategic management in business frontiers, human capacity development and Oil & Gas.  Experienced in multitasking, working under pressure and adhering to strict deadlines.  Proficient in project monitoring and evaluation, as well as determination and implementation of the needed modifications.",
      linkedin: "",
      instagram: "",
      facebook: "",
    },
    {
      image: "",
      name: "Abiodun E. Olaoye",
      position: "Head, Engineering & Operations",
      note: "A top-performing and result-oriented Petroleum Engineer with 26 years’ experience in Well Engineering, Brown field development, Asset Management and Production Enhancement. Adept at managing well site operations, identifying production improvement opportunities, contributing to successful exploration and development efforts with focus on cost reduction and ROI. ",
      linkedin: "",
      instagram: "",
      facebook: "",
    },
    {
      image: "",
      name: "Andrew Agenmomen",
      position: "Engineering Manager",
      note: "Experienced Well Engineer with strong technical and operations management skill. With Oil & Gas industry experience of 25 years, successfully lead and delivered drilling, workover and production enhancement projects in UK, Netherlands, Middle East and Nigeria.",
      linkedin: "",
      instagram: "",
      facebook: "",
    },
    {
      image: "",
      name: "Adaora Ayoade",
      position: "Head, Business Support, HR & Administration",
      note: "A seasoned and technically skilled business professional with a proven record of accomplishment.  Sound commercial, negotiation, managerial, communication and interpersonal skills. Versatile skill-set developed over 20 years of successful extensive experience at the corporate level in human resources, budgeting, sales, marketing, training, development and administration.  ",
      linkedin: "",
      instagram: "",
      facebook: "",
    },
  ];

  const what_we_do = [
    {
      title: "Evoke Clinic Platform",
      description:
        "We dedicate our time to improve the health and lifestyle of patients and their doctors by offering a second medical opinion based on their indicated signs,symptoms, and diagnostic test.",
      target: "_blank",
      link: "https://ases.netlify.app/",
    },
    {
      title: "2nd medical opinion Services",
      description:
        "We dedicate our time to improve the health and lifestyle of patients and their doctors by offering a second medical opinion based on their indicated signs,symptoms, and diagnostic test.",
      link: "/home#services",
    },
    {
      title: "Medical Equipment Services",
      description:
        "We dedicate our time to improve the health and lifestyle of patients and their doctors by offering a second medical opinion based on their indicated signs,symptoms, and diagnostic test.",
    },
  ];

  return (
    <Layout
      subtitle={"  "}
      title={"Our Mission • Our Vision • Our Team"}
      bgimage={require("../media/images/Group27.png").default}
      jumpto="about"
      _renderTitle={AnimatedText}
    >
      {/* end page title */}
      {/* start  section */}
      <section id="about" className="wow pb-0 pb-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-xl-5 col-lg-6 padding-3-rem-bottom lg-padding-7-rem-bottom sm-padding-50px-bottom">
              {/* <div className="text-extra-medium margin-20px-bottom alt-font">Medical opinion services</div> */}
              <h5 className="alt-font text-extra-dark-gray font-weight-500">
                {" "}
              </h5>
              <div className="row">
                {/* <div className="h-1px bg-medium-light-gray margin-40px-tb w-100 xs-margin-30px-tb" /> */}
                {/* start feature box item */}
                {/* <div className="col-12">
									<div className="feature-box feature-box-left-icon">
										<div className="feature-box-content last-paragraph-no-margin">
											<p className="w-95">
												Our doctors are specialists from all fields of medicine
												who volunteer to connect with you and your doctors in
												Nigeria. We dedicate ourselves to offering timely and
												quality responses to all medical inquiries.
												<br />
												<br />
												Our volunteer doctors help patients understand their
												symptoms by explaining their reports in detail and
												choosing treatment plans that are best suited for
												patients. We connect with your doctors in Nigeria to
												review your medical tests, diagnosis and treatment plans
												for optimal outcome.
											</p>
										</div>
									</div>
								</div> */}
                <div>
                  <div className="feature-box feature-box-left-icon">
                    <h3 class="text-dark">Mission</h3>
                    <p>
                      Improve health outcomes among the most vulnerable and
                      underserved populations by facilitating access to timely
                      and affordable health services in our resident states.
                      Improving lives through timely access to doctors thereby
                    </p>
                    <ul>
                      <li>Improving physical and mental health status</li>
                      <li>Preventing diseases </li>
                      <li>Improve quality of life</li>
                      <li>Avoid preventable deaths</li>
                      <li>Increase life expectancy</li>
                    </ul>
                  </div>
                </div>
                <br></br>
                <br></br>
                <div>
                  <div className="feature-box feature-box-left-icon">
                    <h3 class="text-dark">Vision</h3>
                    <p>
                      Transform healthcare delivery in rural communities by
                      building lasting partnerships and optimal use of research,
                      technology, and innovation.{" "}
                    </p>
                  </div>
                </div>
                <br></br>
                <br></br>
                <div>
                  <h3 class="text-dark">Values: WE CARE</h3>

                  <li>Love</li>
                  <li>Compassion</li>
                  <li>Hope</li>
                  <li>Integrity</li>
                  <li>Agility</li>
                </div>
                {/* end feature box item */}
              </div>
            </div>
            <div className="col-12 col-lg-6 offset-xl-1 align-self-center text-center text-xl-left">
              <img
                src={require("../../src/media/images/Group 33.png").default}
                alt=""
                // style={{ paddingBottom: 50 }}
              />
              <div
                className="text-center"
                style={{ color: "grey", fontWeight: 600, fontSize: "15px" }}
              >
                <div>
                  Healthcare workforce shortage impact healthcare supply and
                  access in rural communities. With strategic partnerships and
                  efficient use of technology, 24Telemed strives to bridge
                  service gaps in rural communities and limit barriers to care,
                  including workforce shortages, health insurance status,
                  transportation issues, health literacy, and stigma in rural
                  communities. We design predictable and accessible Telehealth
                  solutions, ranging from telephonic to virtual consultation
                  services filling gaps in healthcare service delivery rural
                  communities across the country.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" bg-light-gray padding-100px-tb md-padding-75px-tb sm-padding-40px-tb wow animate__fadeIn">
        <h2 className="text-center text-dark text-700 mb-5">OUR PARTNERS</h2>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mb-4">
              <img
                src={require("../../src/media/partners/part1.png").default}
                alt=""
                width="200px"
              />
            </div>
            <div className="col-lg-4 mb-4">
              <img
                src={require("../../src/media/partners/part2.png").default}
                alt=""
                width="200px"
              />
            </div>
            <div className="col-lg-4 mb-4">
              <img
                src={require("../../src/media/partners/part5.png").default}
                alt=""
                width="200px"
              />
            </div>
            <div className="col-lg-4 mb-4">
              <img
                src={require("../../src/media/partners/part6.png").default}
                alt=""
                width="200px"
              />
            </div>
            <div className="col-lg-4 mb-4">
              <img
                src={require("../../src/media/partners/lhc.png").default}
                alt=""
                width="200px"
              />
            </div>
            <div className="col-lg-4 mb-4">
            <img
                src={require("../../src/media/partners/assess.jfif").default}
                alt=""
                width="200px"
              />
            </div>
            <div className="col-lg-4 mb-4">
            <img
                src={require("../../src/media/partners/anamb.png").default}
                alt=""
                width="200px"
              />
            </div>
            <div className="col-lg-4 mb-4">
            <img
                src={require("../../src/media/partners/ivm.png").default}
                alt=""
                width="200px"
              />
            </div>
            <div className="col-lg-4 mb-4">
            <img
                src={require("../../src/media/partners/jccmi.png").default}
                alt=""
                width="200px"
              />
            </div>
            <div className="col-lg-4 mb-4">
            <img
                src={require("../../src/media/partners/oweno.png").default}
                alt=""
                width="200px"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
