/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { services, services2 } from "../data/services";
import { socialMedial } from "../data/socialMedial";

const data = {
  header: require("../data/header.json"),
  footer: require("../data/footer.json"),
  site: require("../data/site.json"),
};

const socials = {};

export const TopNavigation = ({ boxheader }) => {
  return (
    <header>
      {/* start navigation */}
      <nav
        className={
          boxheader ||
          "navbar navbar-expand-lg navbar-light bg-transparent header-light fixed-top navbar-boxed header-reverse-scroll border-bottom border-color-black-transparent"
        }
      >
        <div className="container-fluid nav-header-container">
          <div className=" head col-auto col-sm-6 col-lg-2 mr-auto pl-lg-0">
            <a
              className="navbar-brand"
              href="/home"
              style={{ width: "max-content" }}
            >
              <img
                src={require("../media/images/logo.png").default}
                data-at2x={require("../media/images/logo.png").default}
                className="alt-logo"
                alt=""
              />
              <img
                src={require("../media/images/logo.png").default}
                data-at2x={require("../media/images/logo.png").default}
                className="default-logo"
                alt=""
              />

              <img
                src={require("../media/images/logo.png").default}
                data-at2x={require("../media/images/logo.png").default}
                className="mobile-logo"
                alt=""
              />
            </a>
          </div>
          <div
            className="col-auto col-lg-8 menu-order"
            style={{
              paddingLeft: "60px",
            }}
          >
            <button
              className="navbar-toggler float-right"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-line" />
              <span className="navbar-toggler-line" />
              <span className="navbar-toggler-line" />
              <span className="navbar-toggler-line" />
            </button>
            <div
              className="collapse navbar-collapse justify-content-center"
              id="navbarNav"
            >
              <ul className="navbar-nav alt-font">
                {data.header.map((item, i) => {
                  if (item?.name === "Services") {
                    item.items = services2;
                  }

                  return (
                    <li
                      className={`nav-item  ${
                        window.location.pathname === item.link ? "active" : ""
                      } ${(item.items && "dropdown simple-dropdown") || ""}`}
                      key={"nav-" + i}
                    >
                      <a
                        href={item.link}
                        className="nav-link"
                        target={item.ext ? "_blank" : "_self"}
                        style={{ alignItems: "center", display: "inline-flex" }}
                      >
                        {item.icon && (
                          <>
                            <i
                              className={`${item.icon} icon-x-small align-middle text-fast-blue mr-2`}
                            ></i>
                            {"  "}
                          </>
                        )}

                        {item.name}
                      </a>

                      {item.items && (
                        <>
                          <i
                            className="fa fa-angle-down dropdown-toggle"
                            data-toggle="dropdown"
                            aria-hidden="true"
                          ></i>
                          <ul className="dropdown-menu" role="menu">
                            {item.items.map((sub, j) => {
                              return (
                                <li
                                  key={"hshsw-skks-" + i + j}
                                  className={`dropdown ${
                                    window.location.pathname.includes(sub.link)
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <a href={sub.link}>
                                    {sub.name}
                                    <i className="fas fa-angle-right dropdown-toggle"></i>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="col-auto col-lg-2 text-right pr-0 font-size-0">
            <div className="header-social-icon d-inline-block">
              {socialMedial.map((item, i) => (
                <a href={item.link} target="_blank">
                  <i className={item.icon} />
                </a>
              ))}
            </div>
          </div>
        </div>
      </nav>
      {/* end navigation */}
    </header>
  );
};

export const Footer = () => {
  return (
    <>
      <footer className="footer-digital-agency footer-light footer-sticky">
        <div className="footer-top padding-six-tb lg-padding-eight-tb md-padding-50px-tb">
          <div className="container">
            <div className="row">
              {/* start footer column */}
              <div className="col-12 col-lg-3 col-sm-6 order-sm-1 order-lg-0 md-margin-50px-bottom xs-margin-25px-bottom">
                <a
                  href="/"
                  className="footer-logo margin-40px-bottom d-inline-block md-margin-30px-bottom xs-margin-20px-bottom"
                  style={{
                    width: "320px",
                  }}
                >
                  <img
                    src={require("../media/images/logo.png").default}
                    data-at2x={require("../media/images/logo.png").default}
                    alt=""
                    width={500}
                  />
                </a>
                <span className="alt-font font-weight-400 text-extra-large line-height-24px letter-spacing-minus-1-half text-extra-dark-gray d-block w-85 lg-w-100 md-w-70 sm-w-100 xs-w-90">
                Relentlessly breaking down healthcare disparities in Rural Nigeria from project managements to full IT support
                </span>
              </div>
              {/* end footer column */}
              {/* start footer column */}
              <div className="col-12 col-lg-2 offset-lg-1 col-sm-6 order-sm-3 order-lg-0 xs-margin-25px-bottom">
                <span className="alt-font font-weight-500 d-block text-extra-dark-gray text-uppercase text-medium letter-spacing-2px margin-25px-bottom xs-margin-10px-bottom">
                  Company
                </span>
                <ul>
                  {data?.footer.map((item, i) => {
                    return (
                      <li>
                        <a
                          href={item?.link}
                          className="text-capitalizex"
                          target={item?.ext ? "_blank" : "_self"}
                        >
                          {item?.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {/* end footer column */}
              {/* start footer column */}
              <div className="col-12 col-xl-3 offset-xl-0 col-lg-3 col-sm-6 order-sm-4 order-lg-0 xs-margin-25px-bottom">
                <span className="alt-font font-weight-500 d-block text-extra-dark-gray text-uppercase text-medium letter-spacing-2px margin-25px-bottom xs-margin-10px-bottom">
                  Services
                </span>
                <ul>
                  {services2.map((item, i) => {
                    return (
                      <li>
                        <a href={item?.link}>{item?.name}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {/* end footer column */}
              {/* start footer column */}
              <div className="col-12 col-xl-2 offset-xl-1 col-lg-3 col-sm-6 order-sm-2 order-lg-0 md-margin-50px-bottom xs-no-margin-bottom">
                <span className="alt-font font-weight-500 d-block text-extra-dark-gray text-uppercase text-medium letter-spacing-2px margin-25px-bottom xs-margin-25px-bottom">
                  Follow us
                </span>
                <div className="social-icon-style-12">
                  <ul className="extra-small-icon">
                    {socialMedial.map((item, i) => (
                      <li>
                        <a
                          className={item.className}
                          href={item.link}
                          target="_blank"
                        >
                          <i className={item.icon} />
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* end footer column */}
            </div>
          </div>
        </div>
        <div className="footer-bottom padding-5-half-rem-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 order-2 order-md-1 text-sm-center text-md-left last-paragraph-no-margin">
                <p>© {new Date().getFullYear()} 24Telemed </p>
              </div>
              <div className="col-12 col-md-6 order-1 order-md-2 sm-margin-10px-bottom">
                <ul className="footer-horizontal-link d-flex flex-column flex-sm-row justify-content-sm-center justify-content-md-end">
                  {/* <li><a href="/p/privacy-policy">Privacy policy</a></li> */}
                  <li>
                    <a href="/p/payment-refund-policy">
                      Payment and Refund Policy
                    </a>
                  </li>
                  <li>
                    <a href="/p/legal">Legal</a>
                  </li>
                  <li>
                    <a href="/p/terms-of-services">Terms of service</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <a className="scroll-top-arrow" href="javascript:void(0);">
        <i className="feather icon-feather-arrow-up" />
      </a>
    </>
  );
};
