export const services = [
  {
    menu: true,
    name: "General internal medicine",
    link: "/service/medical-2nd-opinion/general-internal-medicine-second-opinion",
    title: "General internal medicine Second opinion",
    bg: require("../media/img/service-1.png").default,
    image: require("../media/img/item-1.png").default,
    description:
      "Internal medicine or general internal medicine is the medical specialty dealing with the prevention, diagnosis, and treatment of internal diseases. General internal medicine is medical specialty that treats minor diseases, identifies severe diseases, and refers patients to specialists for further management.",
    description2: `Our General internal Medicine second opinion offers you easy access to qualified doctors. Easy and convenient. Completely online. Our doctors give you second opinion about diagnosis and refer you to disease specific specialists for further management.		`,
  },
  {
    menu: true,
    name: "General surgery",
    link: "/service/medical-2nd-opinion/general-surgery-second-opinion",
    title: "General surgery second opinion",
    bg: require("../media/img/service-2.png").default,
    image: require("../media/img/item-2.png").default,
    description:
      "General surgery is a surgical specialty that focuses on abdominal contents including the esophagus, stomach, small intestine, large intestine, liver, pancreas, gallbladder, appendix and bile ducts, and often the thyroid gland, including the treatment of injury, deformity, and disease using operative procedures.",
    description2: `Our qualified surgeons offer expert surgical second opinion about your surgical diagnosis by reviewing your radiology and doctor’s reports. Easy and convenient. Completely online. 
		<br/>We recommend you ask a surgeon and get surgery second opinion if you are trying to avoid surgery and are seeking alternative options to the procedure. Some of the most common surgical operations include Appendectomy, Breast biopsy, Carotid endarterectomy, Cholecystectomy, Debridement of wound, burn, or infection, Hemorrhoidectomy, Hysteroscopy, Mastectomy, Prostatectomy and more.
		`,
  },
  {
    menu: true,
    name: "Cardiology",
    link: "/service/medical-2nd-opinion/cardiology-second-opinion",
    title: "Cardiology second opinion",
    bg: require("../media/img/service-3.png").default,
    image: require("../media/img/item-3.png").default,
    description:
      "Cardiology is a branch of medicine that deals with the disorders of the heart as well as some parts of the circulatory system as well as prevent heart illnesses",
    description2: `Seeking a second opinion from our qualified cardiologist does not only provide peace of mind but also helps to prevent unnecessary procedures and offer alternative options of treatment when facing a serious medical situation such as a heart bypass surgery, cardiac catheterization or surgery for valvular heart disease.`,
  },
  {
    menu: true,
    name: "Orthopedic surgery",
    link: "/service/medical-2nd-opinion/orthopedic-surgery-second-opinion",
    title: "Orthopedic surgery Second opinion",
    bg: require("../media/img/service-4.png").default,
    image: require("../media/img/item-4.png").default,
    description:
      "Orthopaedic surgery or orthopaedics, is the branch of surgery concerned with conditions involving the musculoskeletal system concerned with the correction or prevention of deformities, disorders, or injuries of the bones and ligaments.",
    description2: `Our orthopedic team can give you an independent and thorough second opinions of your diagnosis and need for orthopedic surgery or fracture management. They will provide you with the most up to date treatment options, review you MRIs, CT scans, and other radiology results, and discuss findings with you and all your surgical options. The orthopedic team will determine if you have considered all possible treatment options and if there are any other nonsurgical or less invasive options, if surgery is necessary, and type of surgery that should be performed.`,
  },
  {
    menu: true,
    name: "Radiology",
    link: "/service/medical-2nd-opinion/radiology-second-opinion",
    title: "Radiology second opinion",
    bg: require("../media/img/service-5.png").default,
    image: require("../media/img/item-5.png").default,
    description:
      "Radiology is the medical discipline that uses medical imaging to diagnose and treat diseases within the bodies of animals and humans treatment of diseases.",
    description2: `If you have had an MRI or CT scan, and you have questions or want confirmation of the study, results or diagnosis you have been given, our team of radiologist are available to offer you a second opinion consultation which may give you the answers that you need.<br/>
		Our qualified radiologist will offer you: <br/>
		An explanation of your imaging study.<br/>
		An expert opinion about the findings and results of your study.<br/>
		A confirmation of your diagnosis or a differing opinion.<br/>
		Necessary information to make informed decisions when discussing your imaging results with your primary doctor.`,
  },
  {
    menu: true,
    name: "Other specialties",
    link: "/service/medical-2nd-opinion/other-specialties-second-opinion",
    title: "Second opinion",
    bg: require("../media/img/service-6.png").default,
    image: require("../media/img/item-6.png").default,
    services: [
      {
        name: "Anesthesiology",
        description:
          "Anesthesiology is the medical specialty concerned with the total perioperative  care of patients before, during and after surgery.",
      },
      {
        name: "Cosmetology",
        description:
          "Cosmetology is the professional practice of beautifying the skin.",
      },
      {
        name: "Critical care",
        description:
          "Pulmonary and critical care encompasses pulmonary physicians dealing with diseases related to the lungs and medical care in intensive care unit.",
      },
      {
        name: "Dermatology",
        description:
          "Dermatology is the branch of medicine concerned with the diagnosis and treatment of skin disorders.",
      },
      {
        name: "Ear. Nose & Throat",
        description:
          "Ear, nose and throat branch of medicine concerned with diagnosis and treatment of the ear, nose, and throat diseases.",
      },
      {
        name: "Fertility specialist",
        description:
          "Fertility specialist is branch of medicine devoted to treatment of infertility.",
      },
      {
        name: "Gastroenterology",
        description:
          "Gastroenterology is a branch of medicine devoted to diseases of the esophagus, stomach, small and large intestine, pancreas, and live.",
      },
      {
        name: "Geriatric (Elderly) Medicine",
        description:
          "Geriatric medicine is the branch of medicine concerned with the diagnosis, treatment and prevention of disease in older people.",
      },
      {
        name: "Infectious disease",
        description:
          "Infectious disease is a branch of medicine concerned with diseases caused by bacteria, virus or fungus.",
      },
      {
        name: "Neurology",
        description:
          "Neurology is a branch of medicine that is concerned with diagnosis and treatment of nervous system. Stroke, seizures, Alzheimer’s etc",
      },
      {
        name: "Nephrology and HTN",
        description:
          "Nephrology and HTN is a medical specialty concerned with diseases of the kidney.",
      },
      {
        name: "Oncology-he matology",
        description:
          "Oncology hematology is a branch of medicine concerned with treatment and prevention of blood disease and cancer.",
      },
      {
        name: "Obstetric and gynecology",
        description:
          "Obstetrics and gynecology. Obstetrics is a branch of medicine concerned with pregnancy, childbirth and postpartum care. Gynecology covers health of the female reproductive system.",
      },
      {
        name: "Psychiatry",
        description:
          "Psychiatry is a branch of medicine concerned with diagnoses and treatment of mental, emotional, and behavioral disorders.",
      },
      {
        name: "Urology",
        description:
          "Urology is a branch of medicine concerned with treatment of disorders of urinary tract in women and urogenital system in men.",
      },
    ],
  },
];

export const services2 = [
  {
    menu: true,
    name: "24/7 doctors on call service",
    link: "/service/call-service",
    title: "24/7 doctors on call service",
    bg: require("../media/images/e-health/frame-1.png").default,
    image: require("../media/images/e-health/call-center.jpg").default,
    description:
      "24/7 call center tailored to your health needs.<br/><b>CALL US NOW!</b> You call, we answer...<br/>070 4866 3726, 070 4019 9716, 091 357 82822",
    description2: ``,
  },
  {
    menu: true,
    name: "2nd medical opinion",
    link: "/service/medical-2nd-opinion",
    title: "General internal medicine Second opinion",
    image: require("../media/images/pexels-ivan-samkov-4989167.jpg").default,
    bg: require("../media/images/e-health/frame-5.png").default,
    description:
      "Our care team are available 24/7 to connect you to a specialist.<br/><br/>Second Medical Opinion does not replace treatment for your diagnosis. Please follow up with your primary care physician to continue your treatment.<br/>24Telemed gives recommendations for your diagnosis and is not liable for your condition.",
    description2: ``,
  },
  {
    menu: true,
    name: "eConsult",
    link: "https://econsult.24telemed.org",
  },
  // {
  // 	menu: true,
  // 	name: "Pharmaceutical service",
  // 	link: "/pharmaceutical-services",
  // 	title: "Pharmaceutical service",
  // 	image: require("../media/images/e-health/pexels-tima-miroshnichenko-5198239.jpg").default,
  //     bg: require("../media/images/e-health/frame-5.png").default,
  // 	description:
  // 		"2Telemed healthcare analytics for actual claims and cost data, call data and patients’ reviews. Using our analytics, we provide accurate and transparent detailed reports to ensure balance between quality and cost.",
  // 	description2: ``
  // },
  // {
  // 	menu: true,
  // 	name: "Diagnostic service",
  // 	link: "/diagnostic-services",
  // 	title: "Diagnostic service",
  // 	image: require("../media/images/e-health/pexels-tima-miroshnichenko-5198239.jpg").default,
  //     bg: require("../media/images/e-health/frame-5.png").default,
  // 	description:
  // 		"2Telemed healthcare analytics for actual claims and cost data, call data and patients’ reviews. Using our analytics, we provide accurate and transparent detailed reports to ensure balance between quality and cost.",
  // 		description2: ``
  // 	},
  // {
  //   menu: true,
  //   name: "Health data page",
  //   link: "/service/health-data",
  //   title: "Health data page",
  //   image: require("../media/images/health-data.jpg").default,
  //   bg: require("../media/images/e-health/frame-5.png").default,
  //   description:
  //     "2Telemed healthcare analytics for transparent healthcare quality service transactions.",
  //   description2: ``,
  // },
];
